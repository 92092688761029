<template>
  <section class="about">
    <div class="cover about">
      <h2>当院について</h2>
    </div>

    <ul class="breadcrumb">
      <li><router-link to="/">ホーム</router-link></li>
      <li>当院について</li>
    </ul>

    <section class="section-white">
      <Title labelEn="Concept" labelJa="コンセプト" color="brownLitght" />

      <div class="point-card-group">
        <div class="point-card">
          <div class="point">Point</div>
          <div class="number">01</div>
          <h4>大切なのは<br>“自分の体をよく知る”こと</h4>
          <p>治療院や整体院と聞くと、“どんなことでも改善する”と思っていませんか？当院でできることは“体が良くなるためのお手伝い”です。自分がストレスを感じる時、心が解放される時、自分にとっての適度な運動量など、施術の中で自分の知らない自分をたくさん発見してください。それがあなたの体を良い状態へ導く一歩になります。</p>
        </div>

        <div class="point-card">
          <div class="point">Point</div>
          <div class="number">02</div>
          <h4>体を整えながら<br>“悪くならない体”に</h4>
          <p>体はとっても素直です。その人の考え方、性格、行動がとてもよく出ています。施術をしていく中で今の体の状態をしっかりお伝えしていきます。施術の時間が体の痛みや不調を改善するだけでなく、改めて自分を見つめ直すと同時に”新しい自分に気づく時間にしていけたら嬉しいです。</p>
        </div>

        <div class="point-card">
          <div class="point">Point</div>
          <div class="number">03</div>
          <h4>本当の意味での<br>“健康”を</h4>
          <p>現代はストレスを抱えることがとても多くあります。特にここ数年で生活スタイルや人との関わり方も大きく変化しました。当院での施術は、来院されたお客様が心も体も元気に健やかになること、心から笑えること、好きなことを好きなだけできる体でいること、その人らしく生きていけること、そのために必要なこと全てだと考えています。</p>
        </div>
      </div>

    </section>

    <section class="section-green">
      <Title labelEn="Greetings" labelJa="ご挨拶" color="brownLitght" />

      <div class="greetings-group">
        <div class="img">
          <img src="@/assets/img/about/greetings.jpg" alt="">
        </div>
        <div class="text">
          <p>はじめまして。Hapi代表の石井です。<br>
            たくさんの出会い、たくさん施術を受け、たくさん学び、施術を続けて気づけばあっという間に10年以上が経ちました。<br>
            そして多くの方々のご協力や支えがあり、Hapiをオープンすることが出来ました。</p>

          <p>人の体は模型ではありません。形がきれいになっただけでは体が良くなったとは言えません。<br>
          “体が良くなること”にとても大切で必要不可欠なものは“人の心”です。体と心はつながっているので、いくら体がよくなっても、心が元気でなければ本当の意味で“健康”とは言えません。<br>
          一人でも多くの人を笑顔に、そしていつまでも元気で健康でいられる体のお手伝いをしていきたいと思っています。</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Title from '@/components/Title.vue'

export default {
  name: 'HomeView',
  components: {
    Title,
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.section-white {
  padding-top: 40px;
}

.point-card-group {
  @extend %width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @include sp {
    width: 100%;
    padding: 0;
    flex-flow: column;
  }

  .point-card {
    width: 368px;
    padding: 24px;
    background-color: $color-grayBg;

    @include sp {
      width: 100%;
      margin-bottom: 24px;
    }

    .point {
      font-family: 'Montserrat';
      font-size: $font-medium;
      color: $color-primary;
      line-height: 1;

      @include sp {
        font-size: $font-small;
      }
    }

    .number {
      font-size: $font-large5x;
      font-weight: bold;
      color: $color-primary;
      line-height: 1;
      margin-top: 8px;

      @include sp {
        font-size: $font-large3x;
      }
    }

    h4 {
      margin-top: 32px;
      font-size: $font-largeX;
      color: $color-brown;

      @include sp {
        margin-top: 16px;
        font-size: $font-large;
      }
    }

    p {
      color: $color-brown;
      text-align: left;
    }
  }
}

.greetings-group {
  display: flex;
  @extend %width;
  margin: 0 auto;

  @include sp {
    width: 100%;
    flex-flow: column;
    padding: 0 16px;
  }

  .img {
    margin-right: 24px;

    @include sp {
      margin: 0;
    }

    img {
      width: 400px;
      height: auto;

      @include sp {
        width: 100%;
      }
    }
  }

  .text {
    text-align: left;
  }
}

</style>
