<template>
  <section class="news">
    <div class="cover news">
      <h2>お知らせ</h2>
    </div>

    <ul class="breadcrumb">
      <li><router-link to="/">ホーム</router-link></li>
      <li>お知らせ</li>
    </ul>

    <div :class="{ loading: !news, loaded: news }"><img src="@/assets/img/spinner.svg" class="spinner"></div>

    <ul class="news-list">
      <li v-for="item in news" :key="item.id">
        <router-link :to="{ name: 'newsDetail', params: { id: item.id }}"><time>{{ (new Date(item.date)).toLocaleDateString() }}</time><p>{{ item.title }}</p></router-link>
      </li>
    </ul>

  </section>
</template>

<script>
export default {
  name: 'NewsView',
  components: {
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.news-list {
  text-align: center;
  justify-content: center;
  width: 880px;
  margin: 48px auto;

  @include sp {
    width: 100%;
    padding: 0 16px;
    margin: 24px auto 40px auto;
  }

  li {
    a {
      display: flex;
      align-items: center;
      padding: 16px 16px;
      border-bottom: 1px dashed $color-brown;
      text-align: left;
      width: 100%;
      background: url("~@/assets/img/icon_arrow-right.svg") right 16px center no-repeat;
      @extend %trans;

      @include sp {
        flex-flow: column;
        align-items: flex-start;
      }

      &:hover {
        @extend %hover;
      }

      time {
        color: $color-brown;
        display: inline-block;
        margin-right: 24px;
        width: 72px;

        @include sp {
          margin-right: 0;
          font-size: $font-small;
        }
      }
      p {
        color: $color-black;
        display: inline-block;
        margin: 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;
        width: 100%;
      }
    }
    }
}
</style>
