import { createApp } from 'vue'
import VueGtag from 'vue-gtag-next';
import App from './App.vue'
import router from './router'
import mixin from './mixin'

const app = createApp(App)

app.use(VueGtag, {
  property: {
    id: 'G-EZ91VMEDXR'
  }
})

app.use(router)
  .mixin(mixin)
  .mount('#app')
