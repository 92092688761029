const { createClient } = require('microcms-js-sdk');
const client = createClient({ serviceDomain: 'hapi', apiKey: 'b0cb4a04edb049aa8debd9ccd1b605cc440d' });

export default{
  data:()=>({
    news: null,
    newsId: null,
    newsTitle: null,
    newsDate: null,
    newsBody: null,
  }),

  mounted() {
    this.getNews()
  },

  methods: {
    getNews() {
      client
        .get({
          endpoint: 'news',
        })
        .then((res) => {
          this.news = res.contents
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getNewsItem(id) {
      client
        .get({
          endpoint: `news/${id}`,
        })
        .then((res) => {
          this.newsId = res.id
          this.newsTitle = res.title
          this.newsBody = res.body
          this.newsDate = res.date
          window.document.title = this.newsTitle + ` | Hapi(ハピ)【公式】市川真間駅すぐの整体院・女性による施術`

        })
        .catch((err) => {
          console.log(err)
        })
    },
  }
}
