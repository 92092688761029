<template>
  <section class="access-wrap">
    <div class="cover access">
      <h2>アクセス・ご予約について</h2>
    </div>

    <ul class="breadcrumb">
      <li><router-link to="/">ホーム</router-link></li>
      <li>アクセス・ご予約について</li>
    </ul>

    <section class="section-white">
      <div class="access-map">
        <Title labelEn="Access" labelJa="アクセス" color="brownLitght" />

        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5407.411005885723!2d139.90809159244384!3d35.72838342073775!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x711da056f7316a3d!2zSGFwaSjjg4_jg5QpIOODnOODh-OCo-ODoeODs-ODhuODiuODs-OCuSBieSDjgqvjgqTjg63jg5fjg6njgq_jg4bjgqPjg4Pjgq8!5e0!3m2!1sja!2sjp!4v1653522050827!5m2!1sja!2sjp" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="map"></iframe>

        <div class="access-map-group">
          <div class="shop">
            <img src="@/assets/img/shop.jpg" alt="">
          </div>

          <div class="access-map-info">
            <dl>
              <dt>所在地</dt>
              <dd>
                <p>〒272-0035</p>
                <p>千葉県市川市新田5-5-29 トーオービル202号室</p>
                <p class="note">＊1階HANJA GYMさん横の階段をお上がりください</p>
              </dd>
            </dl>
            <dl>
              <dt>電車</dt>
              <dd>
                <p>JR総武線 市川駅 北口から徒歩8分</p>
                <p>京成線 市川真間駅 南口から徒歩1分</p>
              </dd>
            </dl>
            <dl>
              <dt>駐車場</dt>
              <dd>なし<span class="note">※近隣コインパーキングをご利用ください</span></dd>
            </dl>
            <dl>
              <dt>駐輪場</dt>
              <dd>あり<span class="note">※ビル1Fの駐輪場をご利用ください</span></dd>
            </dl>
          </div>
        </div>
      </div>
    </section>


    <section class="section-green reserve">
      <Title labelEn="Reserve" labelJa="予約方法" color="white" />

      <div class="wrap">
        <p>ご予約・お問い合わせは、お電話または公式LINEアカウントにて承っております。<br>
          ご予約の場合は下記事項をお知らせください。<br>
          ①お名前 ②ご連絡先 ③ご希望の日にち、時間帯 ④ご希望のメニュー⑤ご紹介者様のお名前（ご紹介の場合のみ）</p>
        <p>※10分以上遅れた場合、施術時間が短くなる場合があります</p>

        <ul class="button-group">
          <li><a href="tel:047-712-5890" class="button tel"><img src="~@/assets/img/icon_phone.svg" alt="">047-712-5890</a></li>
          <li><a href="https://page.line.me/?accountId=018lmpwh&openQrModal=true" class="button line"><img src="~@/assets/img/access/qr.png" alt="">Hapi公式LINE<br>アカウントを友だち追加</a></li>
        </ul>
      </div>
    </section>
  </section>
</template>

<script>
import Title from '@/components/Title.vue'

export default {
  name: 'AccessView',
  components: {
    Title,
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.section-white {
  padding-top: 40px;
}

.reserve {
  .wrap {
    @extend %width;
    margin: 0 auto;

    @include sp {
      width: 100%;
      padding: 0 16px;
    }

    p {
      text-align: left;
    }

    .button-group {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 56px;

      @include sp {
        flex-flow: column;
        margin-top: 32px;
      }

      li {
        display: inline-block;

        .button {
          width: 260px;
          height: 80px;
          font-size: $font-large2-5x;
          font-weight: bold;
          background-color: $color-brownLitght;
          border: $color-brown 1px solid;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-black;

          @include sp {
            width: 228px;
            height: 80px;
            font-size: $font-large2x;
          }

          &.tel {
            font-weight: bold;

            img {
              margin-right: 8px;
            }
          }

          &.line {
            font-size: $font-small;
            margin-left: 80px;
            padding: 0 8px;

            @include sp {
              font-size: $font-small2x;
              margin: 32px 0 0 0;
            }

            img {
              width: 60px;
              height: 60px;
              vertical-align: middle;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
