<template>
  <section class="menu">
    <div class="cover menu">
      <h2>メニュー</h2>
    </div>

    <ul class="breadcrumb">
      <li><router-link to="/">ホーム</router-link></li>
      <li>メニュー</li>
    </ul>

    <p class="lead-text">各メニュー30分コース・60分コースをご用意しております。<br>
      メニューを組み合わせることも可能ですので、お気軽にご相談ください。</p>

    <div class="anker-button-group">
      <router-link to="#sec-1" class="anker-button">整体・カイロ</router-link>
      <router-link to="#sec-2" class="anker-button">ヘッドマッサージ</router-link>
      <router-link to="#sec-3" class="anker-button">フットマッサージ</router-link>
    </div>

    <div class="menu-group" id="sec-1">
      <h3>整体・カイロ</h3>

      <picture>
        <source srcset="@/assets/img/menu/menu_01.jpg" media="(min-width: 480px)" />
        <img src="@/assets/img/menu/menu_01_sp.jpg" alt="">
      </picture>

      <div class="course">
        <h4>全身矯正コース<span> (約60分）</span></h4>
        <p>全身のほぐし、骨格矯正が入ったトータルケアコースです。<br>
          慢性的な不調のある方にオススメです。</p>
        <div class="price">
          <p>初回限定価格 : <span class="price-text">¥4,000</span><br>約90分 ( カウンセリング込み )</p>
          <p>2回目以降 : ¥7,480</p>
          <p>【回数券】5回券 : ¥33,000円 ( ¥6,600 / 回 ) 10回券 : ¥61,600 ( ¥6,160/ 回 )</p>
        </div>
      </div>

      <div class="course">
        <h4>部分矯正コース<span> (約30分）</span></h4>
        <p>部分的な緩和操作と骨格矯正が入ったコースです。<br>
          学生の方や時間のない方にオススメです。</p>
        <div class="price">
          <p>初回限定価格 : <span class="price-text">¥2,000</span><br>約50分 ( カウンセリング込み )</p>
          <p>2回目以降 : ¥3,850</p>
          <p>【回数券】5回券 : ¥16,500( ¥3,300 / 回 )</p>
        </div>
      </div>

      <div class="center">
        <Button label="ご予約について" link="/access" />
      </div>
    </div>

    <div class="menu-group" id="sec-2">
      <h3>ヘッドマッサージ</h3>

      <picture>
        <source srcset="@/assets/img/menu/menu_02.jpg" media="(min-width: 480px)" />
        <img src="@/assets/img/menu/menu_02_sp.jpg" alt="">
      </picture>

      <div class="course">
        <h4>ヘッドマッサージ 60分コース </h4>
        <p>頭部や目の周りだけでなく、肩周りから血流を良くする集中コースです。<br>
          慢性的な頭痛や眼精疲労のある方にオススメです。</p>
        <div class="price">
          <p>初回限定価格 : <span class="price-text">¥3,500</span><br>60分 ( カウンセリング込み )</p>
          <p>2回目以降 : ¥6,600</p>
          <p>【回数券】5回券 : ¥30,500( ¥6,100/ 回)</p>
        </div>
      </div>

      <div class="course">
        <h4>ヘッドマッサージ 30分コース </h4>
        <p>頭部の筋膜を緩めて血流を良くします。<br>
          頭が重い、眼精疲労のある方にオススメです。</p>
        <div class="price">
          <p>1回: <span class="price-text">¥3,300</span><br>30分 ( カウンセリング込み )</p>
          <p>【回数券】5回券 : ¥15,000(¥3,000 / 回)  </p>
        </div>
      </div>
      <div class="center">
        <Button label="ご予約について" link="/access" />
      </div>
    </div>

    <div class="menu-group" id="sec-3">
      <h3>フットマッサージ</h3>

      <picture>
        <source srcset="@/assets/img/menu/menu_03.jpg" media="(min-width: 480px)" />
        <img src="@/assets/img/menu/menu_03_sp.jpg" alt="">
      </picture>

      <div class="course">
        <h4>フットマッサージ 60分コース </h4>
        <p>酸素の入ったスペシャルオイルを使って膝下全体をケアするコースです。<br>
          慢性的な冷えやむくみのある方にオススメです。</p>
        <div class="price">
          <p>初回限定価格 : <span class="price-text">¥3,700</span><br>60分 ( カウンセリング込み )</p>
          <p>2回目以降 : ¥8,000</p>
          <p>【回数券】5回券 : ¥37,500 ( ¥7,500 / 回 )</p>
        </div>
      </div>

      <div class="course">
        <h4>フットマッサージ 30分コース </h4>
        <p>酸素の入ったスペシャルオイルを使って血流を良くします。<br>
          足裏中心のケアです。</p>
        <div class="price">
          <p>1回: <span class="price-text">¥4,400</span><br>30分 ( カウンセリング込み )</p>
          <p>【回数券】5回券 : ¥20,500 ( ¥4,100 /回 )</p>
        </div>
      </div>
      <div class="center">
        <Button label="ご予約について" link="/access" />
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'MenuView',
  components: {
    Button,
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.anker-button-group {
  display: flex;
  justify-content: center;
  @extend %width;
  margin: 64px auto 0 auto;

  @include sp {
    display: none;
  }

  .anker-button {
    font-size: $font-medium;
    border: 1px solid $color-brown;
    width: 240px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    @extend %trans;

    &:hover {
      @extend %hover;
    }

    &::after {
      content: url('~@/assets/img/icon_arrow-bottom.svg');
      margin-left: 8px;
    }
  }
}

.menu-group {
  background-image: linear-gradient(to bottom, $color-white 0, $color-white 420px, $color-primaryLitght 420px, $color-primaryLitght 100%);
  padding: 64px 0 64px 0;

  @include sp {
    width: 100%;
    padding: 32px 16px;
    background-image: linear-gradient(to bottom, $color-white 0, $color-white 250px, $color-primaryLitght 250px, $color-primaryLitght 100%);

  }

  h3 {
    font-size: $font-large3x;
    padding-bottom: 8px;
    margin: 64px 0 32px 0;
    position: relative;

    @include sp {
      font-size: $font-large2x;
      margin: 16px 0 32px 0;
    }

    &::after {
      content: '';
      width: 280px;
      border-bottom: 1px solid $color-black;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;

      @include sp {
        width: 240px;
      }
    }
  }

  img {
    width: 960px;

    @include sp {
      width:  100%;
    }
  }

  .course {
    background-color: $color-white;
    width: 800px;
    padding: 32px 72px;
    margin: 32px auto;
    text-align: left;

    @include sp {
      width: 100%;
      padding: 16px;
    }

    h4 {
      font-size: $font-large2x;
      font-weight: bold;

      @include sp {
        font-size: $font-large;
      }

      span {
        font-size: $font-largeX;
        font-weight: normal;

        @include sp {
          font-size: $font-medium;
        }
      }
    }

    .price {
      background-color: $color-grayBg;
      padding: 16px;

      p {
        margin: 0;

        br {
          display: none;

          @include sp {
            display: inline;
          }
        }
      }

      &-text {
        color: $color-brown;
        font-size: $font-largeX;
        font-family: 'Montserrat';
        font-weight: bold;
        margin: 0 8px;

        @include sp {
          font-size: $font-medium;
        }
      }
    }
  }
}
</style>
