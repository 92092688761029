import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Menu from '../views/Menu.vue'
import Flow from '../views/Flow.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'
import Access from '../views/Access.vue'
import NotFound from '../views/NotFound.vue'

const titleTemp = ` | Hapi(ハピ)市川真間駅 整体院`

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Hapi(ハピ)【公式】市川真間駅すぐの整体院・女性による施術', desc: 'Hapi(ハピ)は、自然治癒能力を最大限に引き出すお手伝いをします。プライベート空間にて女性スタッフが施術を担当しますので女性特有のお悩みもお気軽にご相談ください。京成線「市川真間駅」より徒歩1分。JR総武線「市川駅」からもアクセス可。' },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: { title: `Hapi(ハピ)について【心を体を整える】${titleTemp}`, desc: '当院では、体の痛みや不調を改善するだけでなく、いつまでも元気で健康でいられる体のお手伝いをしています。京成線「市川真間駅」より徒歩1分です。お気軽にご相談ください。' },
  },
  {
    path: '/menu',
    name: 'menu',
    component: Menu,
    meta: { title: `メニュー【整体・カイロ・マッサージ】${titleTemp}`, desc: '京成線「市川真間駅」より徒歩1分。プライベート空間にて女性スタッフが施術を担当します。全身のほぐしから、頭部や目の周り、足裏まで。安心して施術を受けていただけます。' },
  },
  {
    path: '/flow',
    name: 'flow',
    component: Flow,
    meta: { title: `施術の流れ【整体・カイロ・マッサージ】${titleTemp}`, desc: '経験豊富なカイロプラクターによる施術で、一人ひとり異なる根本の原因を独自の技術で改善に導きます。プライベート空間にて女性スタッフが施術を担当しますので女性特有のお悩みもお気軽にご相談ください。京成線「市川真間駅」より徒歩1分。' },
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: { title: `お知らせ${titleTemp}`, desc: '市川真間駅すぐの整体院 Hapi(ハピ)からのお知らせです。' },
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    component: NewsDetail,
    meta: { title: `お知らせ${titleTemp}`, desc: '市川真間駅すぐの整体院 Hapi(ハピ)からのお知らせです。' },
  },
  {
    path: '/access',
    name: 'access',
    component: Access,
    meta: { title: `アクセス・ご予約について${titleTemp}`, desc: '平日は夜20時まで営業、日曜・祝日も営業しています。ご予約はお電話または公式LINEアカウントにて承っております。当院は、京成線「市川真間駅」からすぐ。JR総武線「市川駅」からもアクセス可。' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: `ページが見つかりません${titleTemp}`, desc: 'Hapi(ハピ)は、自然治癒能力を最大限に引き出すお手伝いをします。プライベート空間にて女性スタッフが施術を担当しますので女性特有のお悩みもお気軽にご相談ください。京成線「市川真間駅」より徒歩1分。JR総武線「市川駅」からもアクセス可。' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      return { top: 0 }
    }
  }
})

trackRouter(router)

export default router
