<template>
  <header class="header">
    <div class="wrap">
      <h1>
        <router-link to="/"><img src="~@/assets/img/logo_type.svg" class="logo" /></router-link>
      </h1>

      <div class="hamburger" :class="{ open: isOpen }" @click="drawr"><span></span><span></span><span></span></div>

      <nav :class="{ open: isOpen }">
        <ul>
          <li :class="{ current: 'home' === this.$route.name }"><router-link to="/" @click="drawr">ホーム</router-link></li>
          <li :class="{ current: 'about' === this.$route.name }"><router-link to="about" @click="drawr">当院について</router-link></li>
          <li :class="{ current: 'menu' === this.$route.name }"><router-link to="/menu" @click="drawr">メニュー</router-link></li>
          <li :class="{ current: 'flow' === this.$route.name }"><router-link to="/flow" @click="drawr">施術の流れ</router-link></li>
          <li :class="{ current: 'news' === this.$route.name }"><router-link to="/news" @click="drawr">お知らせ</router-link></li>
          <li :class="{ current: 'access' === this.$route.name }" class="conversion" @click="drawr"><router-link to="/access">アクセス・ご予約について</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Hapi-Header',

  data:()=>({
    isOpen: false,
  }),

  methods: {
    drawr() {
      this.isOpen = !this.isOpen;
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/css/common.scss';

.header {
  width: 100vw;
  height: 80px;
  background-color: $color-white;
  @extend %shadow-fixed;
  position: fixed;
  z-index: 1000;

  @include sp {
    height: 60px;
  }

  .wrap {
    @extend %width;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include sp {
      width: 100%;
      height: 60px;
      padding: 0 16px;
    }

    h1 {
      width: auto;
      line-height: 0;

      .logo {
        width: 90px;
        height: auto;

        @include sp {
          width: 60px;
          height: auto;
        }
      }
    }

    .hamburger {
      display: none;

      @include sp {
        display: block;
        width: 44px;
        height: 44px;
        position: fixed;
        right: 20px;
        top: 8px;
        z-index: 2000;

        & span {
          display: block;
          height: 2px;
          width: 27px;
          background-color: $color-blue;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all .4s ease;
        }
        & span:first-child {
          top: 25%;
        }
        & span:nth-child(2) {
          top: 50%;
        }
        & span:last-child {
          top: 75%;
        }
        &.open span:first-child {
          top: 50%;
          transform: translate(-50%, -50%) rotate(-135deg);
        }
        &.open span:nth-child(2) {
          transform: translate(-50%, -50%) rotate(45deg);
          opacity: 0;
        }
        &.open span:last-child {
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    nav {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      @include sp {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DAE8DB;
        position: fixed;
        top: 0;
        right: 0;
        right: -100%;
        z-index: 100;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        transition: all .4s ease;

        &.open {
          right: 0;
        }
      }

      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        @include sp {
          flex-flow: column;
          top: 50%;
        }

        li {
          margin: 0 16px;
          border-bottom: $color-white 2px solid;
          transition: all 0.3s ease;

          @include sp {
            margin: 12px 0;
            border: none;
          }

          &.current {
            border-bottom: $color-primary 2px solid;

            @include sp {
              border: none;
            }
          }

          &.conversion {
            padding: 8px 16px;
            margin-right: 0;
            border: $color-black 1px solid;

            @include sp {
              padding: 0;
              border: none;
            }

            &.current {
              border: $color-primary 1px solid;

              @include sp {
                border: none;
              }
            }

            &:hover {
              @extend %hover;
            }
          }

          a {
            font-size: $font-large;
            color: $color-black;
            transition: all 0.3s ease;

            @include sp {
              font-size: $font-large2x;
              font-weight: bold;
              padding: 16px 0;
            }

            &:hover {
              @extend %hover;
            }
          }
        }
      }
    }
  }
}
</style>
