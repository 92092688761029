<template>
  <section class="flow">
    <div class="cover flow">
      <h2>施術の流れ</h2>
    </div>

    <ul class="breadcrumb">
      <li><router-link to="/">ホーム</router-link></li>
      <li>施術の流れ</li>
    </ul>

    <p class="lead-text">当院は予約優先です。事前にご予約のうえご来店ください。<br>
      お着替えは当店でご用意しておりますので、手ぶらでお越しいただけます。</p>

    <div class="anker-button-group">
      <router-link to="#sec-1" class="anker-button"><div>STEP 01</div>ご予約</router-link>
      <router-link to="#sec-2" class="anker-button"><div>STEP 02</div>ご来店</router-link>
      <router-link to="#sec-3" class="anker-button"><div>STEP 03</div>問診票のご記入ヒアリング</router-link>
      <router-link to="#sec-4" class="anker-button"><div>STEP 04</div>検査・施術</router-link>
      <router-link to="#sec-5" class="anker-button"><div>STEP 05</div>身体の状態をご説明アフターケア</router-link>
      <router-link to="#sec-6" class="anker-button"><div>STEP 06</div>お会計<br>次回のご予約</router-link>
    </div>

    <div class="flow-wrap">
      <div class="flow-group">
        <div class="wrap">
          <div id="sec-1"></div>
          <img src="@/assets/img/flow/flow_01.jpg" alt="">
          <div class="text">
            <h3><span>01</span>ご予約</h3>
            <p>お電話もしくは公式LINEアカウントよりご予約を承っております。予約の空き状況と照らし合わせて日時をご提案させていただきます。<br>
              施術中は電話に出られない場合があります。その際はお手数ですが、留守番電話にメッセージを残していただくようにお願いいたします。折り返し連絡させていただきます。</p>
          </div>
        </div>
      </div>

      <div class="flow-group">
        <div class="wrap">
          <div id="sec-2"></div>
          <div class="text">
            <h3><span>02</span>ご来店</h3>
            <p>ベッドや設備の消毒、予約や着替えなどの時間に配慮して施術の間隔は30分程空けております。アルコール消毒、検温、マスクの徹底などのコロナ対策、他の患者様と時間が重ならないようにしておりますので安心してご来院ください。<br>
              着替えは当店でご用意しておりますので、手ぶらでお越しいただけます。</p>
            </div>
            <img src="@/assets/img/flow/flow_02.jpg" alt="">
        </div>
      </div>

      <div class="flow-group">
        <div class="wrap">
          <div id="sec-3"></div>
          <img src="@/assets/img/flow/flow_03.jpg" alt="">
          <div class="text">
            <h3><span>03</span>問診票のご記入・ヒアリング</h3>
            <p>カルテの記入をお願いいたします。<br>
              今一番気になる部位や症状などを教えてください。<br>
              こんなことを聞いてもいいのかな…と思うこともあるかもしれませんが、意外とそれが体を改善する大きなヒントになることもありますので、気になることがあれば遠慮なくご相談ください。</p>
          </div>
        </div>
      </div>

      <div class="flow-group">
        <div class="wrap">
          <div id="sec-4"></div>
          <div class="text">
            <h3><span>04</span>検査・施術</h3>
            <p>お着替え後、体全体のバランスをチェックします。肩の高さや足の長さの違い、体の動きによって痛みが出るかなどをチェックして、体の状態を把握していきます。模型を使って体の状態をわかりやすくご説明いたします。検査を終えたら体全体をほぐし、歪んでいる部分・かたまってしまっている部分を矯正していきます。体を整えることで神経伝達を良くして、体が本来もっている自然治癒能力を高めます。</p>
          </div>
          <img src="@/assets/img/flow/flow_04.jpg" alt="">
        </div>
      </div>

      <div class="flow-group">
        <div class="wrap">
          <div id="sec-5"></div>
          <img src="@/assets/img/flow/flow_05.jpg" alt="">
          <div class="text">
            <h3><span>05</span>体の状態のご説明・アフターケア</h3>
            <p>施術後、再度身体の状態をチェックします。<br>
              施術の前後で立った時の感覚や痛みの違いなどを体感していただけると思います。それと同時に、通っていただく頻度や期間のご提案をさせていただきます。また、施術の効果を長持ちさせるために、施術外でもご自宅でできるストレッチや軽い運動など、1人1人にあわせたものをご提案させていただきます。</p>
          </div>
        </div>
      </div>

      <div class="flow-group">
        <div class="wrap">
          <div id="sec-6"></div>
          <div class="text">
            <h3><span>06</span>お会計・次回のご予約</h3>
            <p>お支払いは現金・各種クレジットカード・paypayに対応しております。<br>
              施術のお会計時に、次回の予約もお取りできます。<br>
              施術中でお伝えした頻度を参考に予約をお取りください。<br>
              次回以降の料金説明もさせていただきます。都度払い、続けやすい回数券もご用意しておりますので、ご自身の通いやすい形をお選びください。</p>
            </div>
            <img src="@/assets/img/flow/flow_06.jpg" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FlowView',
  components: {
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.anker-button-group {
  display: flex;
  justify-content: center;
  @extend %width;
  margin: 64px auto 52px auto;

  @include sp {
    display: none;
  }

  .anker-button {
    font-size: $font-medium;
    border: 1px solid $color-brown;
    width: 136px;
    height: 120px;
    margin-right: 40px;
    position: relative;
    padding: 10px 10px 10px 10px;
    font-weight: bold;
    font-size: $font-small;
    @extend %trans;

    &:hover {
      @extend %hover;
    }

    div {
      font-family: 'Montserrat';
      font-size: $font-largeX;
      margin: 8px 0;
      color: $color-primary;
    }

    &::after {
      content: url('~@/assets/img/icon_arrow-bottom.svg');
      position: absolute;
      left: calc(50% - 4px);
      bottom: 4px;
    }
  }
}

.flow-wrap {

  @include sp {
    padding-top: 0;
  }

  .flow-group {
    background-color: $color-primaryLitght;
    padding: 56px 0;

    @include sp {
      padding: 32px 0;
      margin-top: 0;
    }

    &:nth-of-type(even){
      background-color: $color-white;

      .wrap {

        @include sp {
          display: flex;
          flex-direction: column-reverse;
        }

        img {
          margin-left: 40px;
          margin-right: 0;

          @include sp {
            margin: 0;
          }
        }
      }
    }

    .wrap {
      display: flex;
      @extend %width;
      margin: 0 auto;

      @include sp {
        width: 100%;
        flex-flow: column;
      }

      img {
        width: 400px;
        height: 300px;
        margin-right: 40px;

        @include sp {
          width: 100%;
          height: auto;
          margin: 0;
        }
      }

      .text {
        text-align: left;

        h3 {
          font-size: $font-large2-5x;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 24px;

          @include sp {
            font-size: $font-large;
            margin: 24px 0;
          }

          span {
            color: rgba(0,0,0,0);
            text-stroke: 1px $color-brown;
            -webkit-text-stroke: 1px $color-brown;
            font-size: $font-large5x;
            margin: 0 24px 0 0;
            line-height: 1;
            display: inline-block;
            vertical-align: text-bottom;

            @include sp {
              font-size: $font-large3x;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}

#sec-1,
#sec-2,
#sec-3,
#sec-4,
#sec-5,
#sec-6 {
  margin-top: -120px;
  visibility: hidden;
  width: 1px;
  height: 1px;

  @include sp {
    display: none;
  }
}
</style>
