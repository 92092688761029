<template>
  <section class="title">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="96.243" height="100" viewBox="0 0 96.243 100">
      <g id="group" data-name="group" clip-path="url(#clip-path)">
        <path id="feather" :class="`feather-${color}`" data-name="feather" d="M40.154,37.026l-1.061-5.882C16.3,52.99,20.785,72.225,20.785,72.225S-2.1,97.975.158,99.992c1.073.959,80.586-86.374,82.141-84.985S24.389,77.53,24.389,77.53c27.54,0,37.969-13.622,37.969-13.622L46.213,62.014c21.84,0,27.537-8.549,27.537-8.549l-6.172-1.9c13.3-5.7,23.269-23.742,23.269-23.742l-5.223-.474s7.123-9.019,9.023-15.194A38.931,38.931,0,0,0,96.224.1C59.662-2.27,40.154,37.026,40.154,37.026" transform="translate(-0.001 0)" fill="#000000" />
      </g>
    </svg>
    <div class="text">
      <p>{{ labelEn }}</p>
      <h3>{{ labelJa }}</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hapi-Title',
  props: {
    labelEn: {
      type: String,
      required: true,
    },

    labelJa: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.title {
  position: relative;
  margin-bottom: 64px;


  @include sp {
    margin-bottom: 24px;
  }

  .feather-white {
    fill: $color-white;
  }

  .feather-brownLitght {
    fill: $color-brownLitght;
  }

  .text {
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;

    @include sp {
      top: 16px;
    }

    p {
      font-size: $font-large4x;
      font-family: 'Montserrat', sans-serif;
      margin: 0;
      text-align: center;
      line-height: 1;

      @include sp {
        font-size: $font-large2x;
      }
    }

    h3 {
      font-size: $font-medium;
      margin: 4px 0;;
      text-align: center;
      font-weight: 400;

      @include sp {
        font-size: $font-small2x;
      }
    }
  }
}
</style>
