<template>
  <section class="home">
    <div class="hero">
      <div class="hero-content">
        <img src="@/assets/img/logo_white.svg" alt="">
        <p>心と体を整えて<br>自分らしい生き方を</p>
      </div>
    </div>

    <div class="news-top">
      <div class="news-top-wrap">
        <h2>News</h2>
        <div :class="{ loading: !news, loaded: news }"><img src="@/assets/img/spinner.svg" class="spinner"></div>

          <template v-for="(item, index) in news" :key="index">
            <template v-if="index === 0">
              <router-link :to="{ name: 'newsDetail', params: { id: item.id }}">
              <time>{{ (new Date(item.date)).toLocaleDateString() }}</time>
              <p>{{ item.title }}</p>
            </router-link>
            </template>
          </template>
        <router-link to="/news" class="news-list-link">お知らせ一覧を見る</router-link>
      </div>
    </div>

    <section class="section-white sec-about">
      <Title labelEn="About" labelJa="当院について" color="brownLitght" />
      <div class="about">
        <div class="img">
          <img src="@/assets/img/home/about_img.jpg" alt="">
        </div>

        <div class="text">
          <h3>自分自身が持っている素晴らしい自然治癒能力を最大限に引き出し、心と体のバランスを整えるお手伝いをします。</h3>
          <p>カイロプラクティックとは、体の歪みを矯正することで神経伝達を良くし、その人それぞれがもっている自然治癒能力を高める技術です。<br>
            当院では他の方と重なることのないプライベート空間で施術を受けていただけます。女性の施術者ですので女性特有のお悩みもお気軽にご相談ください。また、妊娠中の方、お子様連れでも施術可能です。</p>
          <div class="right">
            <Button label="詳しくはこちら" link="/about" />
          </div>
        </div>
      </div>
    </section>

    <section class="section-green">
      <Title labelEn="Menu" labelJa="メニュー" color="white" />

      <div class="menu-group">
        <div class="menu menu_1">
          <div class="menu-content">
            <h3>整体・カイロ</h3>
            <p>骨盤や体の歪み、冷え、自律神経の乱れなど、慢性的な不調のある方にオススメです。</p>
            <div class="center">
              <Button label="詳しくはこちら" link="/menu#sec-1" :isAnker="true" />
            </div>
          </div>
        </div>

        <div class="menu menu_2">
          <div class="menu-content">
            <h3>ヘッドマッサージ</h3>
            <p>目の疲れ・頭がだるく重いと悩まされる方にオススメです。</p>
            <div class="center">
              <Button label="詳しくはこちら" link="/menu#sec-2" :isAnker="true" />
            </div>
          </div>
        </div>

        <div class="menu menu_3">
          <div class="menu-content">
            <h3>フットマッサージ</h3>
            <p>慢性的な冷えやむくみのある方にオススメです。</p>
            <div class="center">
              <Button label="詳しくはこちら" link="/menu#sec-3" :isAnker="true" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-white w100vw">
      <Title labelEn="Voice" labelJa="お客様の声" color="brownLitght" />

      <div class="voise">
         <Carousel :items-to-show="2" :wrap-around="true"  :settings="settings" :breakpoints="breakpoints">
          <slide v-for="slide in voice" :key="slide">
            <div class="voice-item carousel__item">
              <p>{{ slide.text }}</p>
              <span class="age">{{ slide.age }}</span>
            </div>
          </slide>

          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </section>

    <section class="section-green sec-news">
      <Title labelEn="News" labelJa="お知らせ" color="white" />

      <div class="news">
        <ul>
          <li v-for="item in news" :key="item.id">
            <router-link :to="{ name: 'newsDetail', params: { id: item.id }}"><time>{{ (new Date(item.date)).toLocaleDateString() }}</time><p>{{ item.title }}</p></router-link>
          </li>
        </ul>
      </div>
      <div class="center">
        <Button label="お知らせ一覧を見る" link="/news" />
      </div>
    </section>

    <section class="section-white sec-access">
      <div class="access-map">
        <Title labelEn="Access" labelJa="アクセス" color="white" />

        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5407.411005885723!2d139.90809159244384!3d35.72838342073775!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x711da056f7316a3d!2zSGFwaSjjg4_jg5QpIOODnOODh-OCo-ODoeODs-ODhuODiuODs-OCuSBieSDjgqvjgqTjg63jg5fjg6njgq_jg4bjgqPjg4Pjgq8!5e0!3m2!1sja!2sjp!4v1653522050827!5m2!1sja!2sjp" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="map"></iframe>
        <div class="access-map-group">
          <div class="shop">
            <img src="@/assets/img/shop.jpg" alt="">
          </div>

          <div class="access-map-info">
            <dl>
              <dt>所在地</dt>
              <dd>
                <p>〒272-0035</p>
                <p>千葉県市川市新田5-5-29 トーオービル202号室</p>
                <p class="note">＊1階HANJA GYMさん横の階段をお上がりください</p>
              </dd>
            </dl>
            <dl>
              <dt>電車</dt>
              <dd>
                <p>JR総武線 市川駅 北口から徒歩8分</p>
                <p>京成線 市川真間駅 南口から徒歩1分</p>
              </dd>
            </dl>
            <dl>
              <dt>駐車場</dt>
              <dd>なし<span class="note">※近隣コインパーキングをご利用ください</span></dd>
            </dl>
            <dl>
              <dt>駐輪場</dt>
              <dd>あり<span class="note">※ビル1Fの駐輪場をご利用ください</span></dd>
            </dl>
          </div>
        </div>
      </div>
    </section>

  </section>
</template>

<script>
import Title from '@/components/Title.vue'
import Button from '@/components/Button.vue'
import '@/assets/css/override/carousel.scss';
import { Carousel, Pagination, Slide } from 'vue3-carousel';

export default {
  name: 'HomeView',
  components: {
    Title,
    Button,
    Carousel,
    Slide,
    Pagination,
  },
  data:()=>({
    voice:[
      {
        text: 'ガチガチに固まって、動きも悪くなっていた肩甲骨周りをほぐしていただきました。施術はもちろんですが、施術中いつも明るく楽しく過ごせるので、気持ちもほぐされます。完全個室なので気楽ですし、場所も駅近で通いやすいです。',
        age: '30代 女性',
      },
      {
        text: '施術者の石井さんにはかれこれ4〜5年、夫婦共々お世話になっています。石井さんの明るい人柄にいつも元気をいただいています。施術も、ピンポイントで不調にアプローチしてくださるので、施術後はいつも身体が軽くなり、とてもスッキリします。定期的に通いたくなるサロンです！',
        age: '30代 女性',
      },
      {
        text: '心身の状態が悪いときは週2回通ってましたが、今は都合上月1回です。でも、嫌な顔せずに月1回の整体で済むように運動を進めてくれました。聞くと答えてくれて私に合わせてくれる整体師さんです。そして、本当の意味で体の事を考えてくれる人です。',
        age: '30代 女性',
      },
      {
        text: '丸かった背中が、まっすぐになりました。１週間でガッチリ固めてきた首や肩を一瞬でなおしてくれます。通っていなかったら、今頃私の体は動かなくなっていたと思います。明るい先生で、いつも楽しく通わせていただいています。',
        age: '30代 女性',
      },
    ],
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
      touchDrag: true,
      mouseDrag: true,
    },
    breakpoints: {
      320: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      560: {
        itemsToShow: 1.8,
        snapAlign: 'center',
      },
      860: {
        itemsToShow: 2.5,
        snapAlign: 'center',
      },
      960: {
        itemsToShow: 1.5,
        snapAlign: 'center',
      },
      1320: {
        itemsToShow: 2,
        snapAlign: 'center',
      },
      1656: {
        itemsToShow: 2.5,
        snapAlign: 'center',
      },
    },
  }),
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.hero {
  width: 100vw;
  height: 600px;
  padding: 0 12px;
  background: url('@/assets/img/home/hero_bg.jpg') center center no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @include sp {
    height: 480px;
  }

  &-content {
    width: 512px;
    padding: 40px;
    text-align: center;
    background-color: rgba($color-primaryPattern2, 0.75);

    @include sp {
      width: 270px;
      height: auto;
      padding: 24px;
    }

    img {
      @include sp {
        width: 152px;
      }
    }

    p {
      font-size: $font-large2x;
      text-shadow: 2px 4px 16px $color-black;
      color: $color-white;

      @include sp {
        margin-bottom: 12px;
      }
    }
  }
}

.news-top {
  background-color: $color-primaryLitght;

  &-wrap {
    @extend %width;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;

    @include sp {
      width: 100%;
      display: block;
      padding: 16px;
      text-align: left;
    }

    h2 {
      display: inline-block;
      margin-right: 32px;
      line-height: 1;
      vertical-align: text-bottom;

      @include sp {
        width: 100%;
        text-align: left;
        border-bottom: 1px dashed $color-brown;
        font-size: $font-large;
        padding-bottom: 8px;
        margin: 0;
      }
    }

    a {
      display: flex;
      align-items: center;

      @include sp {
        width: 100%;
        display: block;
        background: url("~@/assets/img/icon_arrow-right.svg") right center no-repeat;
      }

      time {
        color: $color-brown;
        margin-right: 32px;
        line-height: 1;

        @include sp {
          margin: 12px 0 0 0;
          font-size: $font-small;
          display: block;
        }
      }

      p {
        margin: 4px 32px 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 120px);
        max-width: 740px;

        @include sp {
          width: 100%;
        }
      }
    }

    a.news-list-link {
      padding-right: 16px;
      display: inline-block;
      background: url("~@/assets/img/icon_arrow-right.svg") right center no-repeat;
      line-height: 1;
      white-space: nowrap;

      @extend %trans;

      &:hover {
        @extend %hover;
      }

      @include sp {
        display: none;
      }
    }
  }
}

.sec-about {
  background: url("~@/assets/img/home/section_bg_1.png") right -56px top -56px no-repeat;

  @include sp {
    background: url("~@/assets/img/home/section_bg_1.png") right -56px top -56px no-repeat;
    background-size: 168px 168px;
    padding: 64px 0;
  }

  .about {
    display: flex;
    @extend %width;
    margin: 0 auto;

    @include sp {
      width: 100%;
      flex-flow: column;
    }

    .img {
      width: 456px + 12px;
      margin-right: 64px;

      @include sp {
        width: calc(100% - 16px);
        margin: 0 0 0 4px;
      }

      img {
        width: 480px;
        height: 360px;
        box-shadow: 12px 12px 0 0 $color-primaryLitght;

        @include sp {
          width: 100%;
          height: auto;
          margin-right: 0;
        }
      }
    }

    .text {
      flex: 1;
      text-align: left;

      h3 {
        font-size: $font-large2x;

        @include sp {
          font-size: $font-large;
          margin-top: 32px;
        }
      }

      p {
        margin: 40px 0;

        @include sp {
          margin-top: 16px;
        }
      }
    }

    .right {
      @include sp {
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.menu-group {
  display: flex;
  justify-content: center;

  @include sp {
    flex-flow: column;
  }

  .menu {
    width: 400px;
    height: 540px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sp {
      width: 100%;
    }

    .menu-content {
      width: 320px;
      color: $color-white;

      h3 {
        font-size: $font-large3x;

        @include sp {
          font-size: $font-large2x;
        }
      }

      p {
        margin: 24px 0 64px 0;
      }
    }
  }

  .menu_1 {
    background: url("~@/assets/img/home/menu_img01.jpg") center center no-repeat;
    background-size: cover;
  }

  .menu_2 {
    background: url("~@/assets/img/home/menu_img02.jpg") center center no-repeat;
    background-size: cover;

  }

  .menu_3 {
    background: url("~@/assets/img/home/menu_img03.jpg") center center no-repeat;
    background-size: cover;

  }
}

.voice {

  &-item {
    width: 640px;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-primaryLitght url('~@/assets/img/home/silhouette_1.svg') left 24px bottom no-repeat;
    position: relative;

    @include sp {
      width: 335px;
    }

    p {
      background: url('~@/assets/img/home/bubble_lg.svg') center center no-repeat;
      width: 440px;
      height: 284px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px;
      text-align: left;

      @include sp {
        width: 100%;
        padding: 0px 48px;
        background: url('~@/assets/img/home/bubble_sm.svg') center center no-repeat;
        background-size: 300px auto;
        font-size: $font-small2x;
      }
    }

    .age {
      color: $color-brown;
      position: absolute;
      left: 100px;
      bottom: 20px;
    }
  }
}

.sec-news {
  background: $color-primaryLitght url("~@/assets/img/home/section_bg_2.png") right 200px bottom 10px no-repeat;

  @include sp {
    background-size: 104px auto;
    background-position: right 12px bottom 16px;
  }

  .news {
    ul {
      text-align: center;
      justify-content: center;
      width: 880px;
      margin: 48px auto;

      @include sp {
        width: 100%;
        padding: 0 16px;
      }

      li {
        a {
          display: flex;
          align-items: center;
          padding: 16px 16px;
          border-bottom: 1px dashed $color-brown;
          text-align: left;
          width: 100%;
          background: url("~@/assets/img/icon_arrow-right.svg") right 16px center no-repeat;
          @extend %trans;

          @include sp {
            flex-flow: column;
            align-items: flex-start;
          }

          &:hover {
            @extend %hover;
          }

          time {
            color: $color-brown;
            display: inline-block;
            margin-right: 24px;
            width: 72px;

            @include sp {
              margin-right: 0;
              font-size: $font-small;
            }
          }

          p {
            color: $color-black;
            display: inline-block;
            display: block;
            margin: 4px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 16px;
            width: 100%;
          }
        }
      }
    }
  }
}

.sec-access {
  background: url("~@/assets/img/home/section_bg_1.png") left 50px top -100px no-repeat;
  background-size: 272px 272px;

  @include sp {
    background-size: 168px auto;
    background-position: left -32px top -32px;
  }
}

</style>
