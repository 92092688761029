<template>
  <div id="app" v-cloak>
    <Header />

    <main>
      <router-view />
    </main>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },

  watch: {
    '$route' (routeInstance) {
      this.settitle(routeInstance)
    },
  },

  mounted() {
    this.settitle(this.$route)
  },

  methods: {
    settitle(routeInstance) {
      // タイトル設定
      if (routeInstance.meta.title) {
        window.document.title = routeInstance.meta.title
      }
      // Description設定
      if (routeInstance.meta.desc) {
        console.log(routeInstance.meta.desc)
        document.querySelector('meta[name="description"]').setAttribute('content', routeInstance.meta.desc)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/css/common.scss';

#app {
  font-family: 'Noto Sans JP', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-black;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  overflow: hidden;

  main {
    flex: 1;
    padding-top: 80px;
    max-width: 100vw;
    margin: 0 auto;

    @include sp {
      padding-top: 60px;
    }
  }
}
</style>
