<template>
  <section class="notfound">
    <section class="section-white notfound-sec">
      <h3>お探しのページが見つかりません</h3>
      <p>申し訳ございませんが、お探しのページが見つかりませんでした。<br>
        お探しのページは一時的にアクセスできない状況にあるか移動もしくは削除された可能性があります。</p>
    </section>

    <div class="center">
      <Button label="トップページに戻る" link="/" />
    </div>

  </section>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'NotFound',
  components: {
    Button,
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.notfound {
  .notfound-sec {
    padding-left: 16px;
    padding-right: 16px;

    h3 {
      font-size: $font-large2x;
      padding: 16px 0;
      text-align: left;
      border-bottom: 1px solid $color-black;

      @include sp {
        font-size: $font-large;
      }
    }

    p {
      text-align: left;
      white-space: pre-line;
    }
  }
}
</style>
