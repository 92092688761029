<template>
  <footer class="footer">
    <div class="wrap">
      <div class="symbol-box">
          <img src="~@/assets/img/logo_radation.svg" class="symbol" />
      </div>

      <div class="text-box">
        <p>〒272-0035<br>
        千葉県市川市新田5-5-29 トーオービル202</p>
        <p class="tel"><img src="~@/assets/img/icon_phone.svg" alt="">047-712-5890</p>
        <p class="note">施術中は電話に出れない場合がありますので、<br>留守番電話にメッセージを残していただけると幸いです。</p>
        <p>平日：10:00〜20:00<br class="sp-br">土・日・祝日：10:00〜18:00<br>
        ＊不定休</p>
      </div>

        <a href="https://page.line.me/?accountId=018lmpwh" class="conversion-box">
          <img src="~@/assets/img/icon_line.png" alt="">
          <span>ご予約・お問い合わせ</span>
        </a>
    </div>

    <small>© Hapi Body maintenance by Chiropractic.</small>
  </footer>
</template>

<script>
export default {
  name: 'Hapi-Footer',
}
</script>

<style scoped lang="scss">
@import '~@/assets/css/common.scss';

.footer {
  width: 100vw;
  background-color: $color-grayBg;
  color: $color-black;
  font-size: $font-small;
  padding: 40px 0;
  margin-top: 56px;

  @include sp {
    padding: 40px 16px;
  }

  .wrap {
    @extend %width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @include sp {
      width: 100%;
      flex-flow: column;
    }

    .symbol-box {
      margin-right: 80px;

      @include sp {
        margin: 0;
      }

      .symbol {
        width: 142px;
        height: auto;
      }
    }

    .text-box {
      flex: 1;
      text-align: left;

      .tel {
        font-size: $font-large2x;
        font-weight: bold;
        margin: 0;

        img {
          margin-right: 8px;
        }
      }

      .note {
        color: $color-brown;
        margin: 0;

      }
    }

    .conversion-box {
      width: 260px;
      height: 80px;
      font-size: $font-medium;
      font-weight: bold;
      background-color: $color-brownLitght;
      border: $color-brown 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-black;
      @extend %trans;

      @include sp {
        width: 228px;
        height: 56px;
        font-size: $font-small;
        margin: 24px auto 32px auto;
      }

      &:hover {
        @extend %hover;
      }

      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
        margin-right: 16px;

        @include sp {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>
