<template>
  <section class="news-detail">
    <div class="cover news">
      <h2>お知らせ</h2>
    </div>

    <ul class="breadcrumb">
      <li><router-link to="/">ホーム</router-link></li>
      <li><router-link to="/news">お知らせ</router-link></li>
      <li>{{ newsTitle }}</li>
    </ul>

    <div :class="{ loading: !newsTitle, loaded: newsTitle }"><img src="@/assets/img/spinner.svg" class="spinner"></div>

    <section class="section-white news-sec" v-if="newsTitle">
      <time>{{ date }}</time>
      <h3>{{ newsTitle }}</h3>
      <p>{{ newsBody }}</p>
    </section>

    <div class="center btn-group">
      <Button label="お知らせ一覧へ戻る" link="/news" />
    </div>

  </section>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'NewsDetailView',
  components: {
    Button,
  },
  computed: {
    date() {
      return new Date(this.newsDate).toLocaleDateString()
    },
  },
  mounted() {
    this.getNewsItem(this.$route.params.id)
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';

.news-sec {
  @extend %width;
  padding-top: 32px;

  @include sp {
    width: 100%;
    padding: 32px 16px;
  }

  time {
    color: $color-brown;
    font-size: $font-small;
    text-align: left;
    width: 100%;
    display: block;
  }

  h3 {
    font-size: $font-large2x;
    padding: 16px 0;
    text-align: left;
    border-bottom: 1px solid $color-black;

    @include sp {
      font-size: $font-large;
    }
  }

  p {
    text-align: left;
    white-space: pre-line;
  }
}

.btn-group {
  margin: 32px 0;
}
</style>
