<template>
  <span class="button" @click="ankerLink">
    <router-link :to="link">{{ label }}</router-link>
  </span>
</template>

<script>
export default {
  name: 'Hapi-Button',
  props: {
    label: {
      type: String,
      required: true,
    },

    link: {
      type: String,
      required: true,
    },

    isAnker: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    // 別ページのスムーススクロール
    ankerLink() {
      if(this.isAnker){
        window.scroll({top: 0 });
        this.$router.push(this.link)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/common.scss';


.button {
  text-align: center;

  a {
    display: block;
    width: 200px;
    height: 48px;
    background-color: $color-brownLitght;
    padding: 12px 24px;
    color: $color-black;
    font-weight: bold;
    font-size: $font-medium;
    transition: background 0.3s ease;
    position: relative;

    &:hover {
      background-color: darken($color-brownLitght, 4%);
    }

    &:active {
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.08);
    }

    /*線の設定*/
    &::before, &::after {
      content: '';
      /*絶対配置で線の位置を決める*/
      position: absolute;
      /*事前に出現させる線の形状*/
      border: 1px solid $color-black;
      width: 10px;
      height: 10px;
      /*アニメーションの指定*/
      transition: all 0.4s ease-in-out;
    }
    &::before {
      /*事前に出現させる線の位置*/
      top: 4px;
      left: 4px;
      /*事前に出現させる線の形状*/
      border-width: 1px 0 0 1px;
    }
    &::after {
      /*事前に出現させる線の位置*/
      bottom: 4px;
      right: 4px;
      /*事前に出現させる線の形状*/
      border-width: 0 1px 1px 0;
    }
    /*hoverした際の線の形状*/
    &:hover::before, &:hover::after {
      width: calc(100% - 9px);
      height: calc(100% - 9px);
      border-color: $color-black;
    }
  }
}
</style>
